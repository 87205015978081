@import "./_variables.scss"; // Global Variables

body {
  background: $theme-color;
  padding: 0;
}

.nonvisible {
  display: none;
}

.hide-m-plus-safety-mobile {
  display: none;
}

/* Genral Icon Hover */
.icon {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 35px;
  height: 35px;
  border-radius: $border-radius;

  &:hover {
    background: $theme-color;
  }
}

/* Dashboar Layout*/
#dashboard-layout {
  position: relative;
  background: $theme-color;
  font-family: "Lato", sans-serif;
  font-size: 13px;

  #dashboard {
    position: relative;
    padding: 30px 30px 0 130px;
    z-index: 1;
    min-height: calc(100vh - 65px);
    transition: padding 0.4s ease-in-out;

    .dashboard-container {
      padding: 0 0 0 30px;
      position: relative;
    }
  }
}

/* Navigation Side Bar*/
#navbar {
  z-index: 999;
  transition: display 0.4s ease-in-out;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  position: fixed;
  justify-content: space-between;
  text-align: center;
  width: 100px;
  top: 30px;
  left: 30px;
  min-height: calc(100vh - 95px);
  background: #ffffff;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.1);
  border-radius: $border-radius;
  padding: 20px 0;

  .content {
    display: flex;
    flex-direction: column;

    .user {
      padding: 40px 0 27px 0;

      .user-img {
        border: 5px solid $theme-color-2;
        border-radius: 100%;
        margin-bottom: 27px;
      }

      .user-icons {
        display: flex;
        justify-content: center;
        gap: 35px;
        margin-bottom: 27px;

        .icon {
          display: flex;
          justify-content: center;
          align-items: center;
          text-align: center;
          width: 30px;
          height: 30px;
          border-radius: $border-radius;

          &:hover {
            background: $theme-color;
          }
        }

        img {
          cursor: pointer;
        }
      }
    }

    .menu {
      display: flex;
      flex-direction: column;
      gap: 20px;

      .menu-item {
        display: flex;
        flex-direction: column;
        gap: 20px;
        font-size: 13px;
        color: $theme-color-3;

        .content {
          cursor: pointer;
          padding: 10px 0;
          margin: 0 13px;
          border-radius: 15px;

          .menu-icon {
            margin-bottom: 9px;

            svg {
              width: 20px;
            }
          }

          &:hover {
            background: $theme-color;
          }
        }
        .active-content {
          border-radius: 15px;
          background-color: $theme-color-2;
          color: $theme-color-1;

          &:hover {
            background: $theme-color-2;
          }

          svg {
            path {
              stroke: $theme-color-1 !important;
            }
            rect {
              stroke: $theme-color-1 !important;
            }
          }
          .user-manage-icon {
            path {
              fill: $theme-color-1 !important;
            }
          }

          .department-icon {
            path {
              fill: $theme-color-1 !important;
            }
          }

          .graph-icon {
            path {
              fill: $theme-color-1 !important;
            }
          }

          .chart-icon {
            path {
              fill: $theme-color-1 !important;
            }
          }
        }
      }
    }
  }

  .line {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    hr {
      border-radius: 2px;
      border: 2px solid rgba(76, 78, 100, 0.3);
      width: 28px;
      margin: 0;
      padding: 0;
    }
  }

  .menu-footer {
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      cursor: pointer;
    }
  }
}

/* Top Info */
.top-info {
  display: flex;
  flex-direction: row;
  gap: 20px;

  #top-info-card {
    background: $theme-color-1;
    box-shadow: 0px 0px 6px rgba(221, 221, 221, 0.25);
    border-radius: $border-radius;
    min-height: 120px;
    width: 20%;
    padding: 20px 30px;
    display: flex;
    flex-direction: column;
    gap: 22px;

    .content {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      .title {
        font-size: 14px;
        color: $theme-color-3;
        font-size: 13px;
        line-height: 16px;
        margin-bottom: 6px;
      }

      .subtitle {
        font-size: 12px;
        line-height: 14px;
        color: rgba(76, 78, 100, 0.3);
      }
    }
    .count {
      color: $theme-color-3;
      font-weight: 700;
      font-size: 18px;
      line-height: 22px;
    }
  }
}

/* Main Card */
#main-card {
  padding: 50px;
  margin-top: 30px;
  background: #ffffff;
  box-shadow: 0px 0px 6px rgba(221, 221, 221, 0.25);
  border-radius: $border-radius;
  width: 100%;
  min-height: calc(100vh - 249px);

  .header {
    display: flex;
    align-items: start;
    gap: 20px;
    margin-bottom: 50px;
    position: relative;

    .icon {
      padding-top: 3px;
    }

    .content {
      font-size: 24px;
      color: $theme-color-3;

      .title {
        font-weight: 700;
      }

      .subtitle {
        margin-top: 10px;
        font-size: 12px;
        max-width: 280px;
        line-height: 25px;
        color: rgba(76, 78, 100, 0.3);
      }
    }
  }

  .m-plus-safety-image {
    position: relative;
    width: 100%;
    max-width: 900px;
    aspect-ratio: 100 /71.48;
    margin: 0 auto;
    box-sizing: border-box;
    & > img {
      position: absolute;
      top: 0;
      right: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}

.door {
  position: absolute;
  z-index: 2;
  top: 12%;
  left: 67.8%;
  width: 17%;

  img {
    width: 100%;
  }
}

.auth-control {
  position: absolute;
  z-index: 2;
  top: 59.6%;
  left: 72.2%;
  width: 14.6%;

  img {
    display: none;
    width: 100%;

    &.active {
      display: block;
    }
  }
}

/* User Management*/
.user-management {
  z-index: 5;

  #user-card {
    width: 100%;
    margin-top: 30px;
    padding: 10px 0 0 0;

    .swiper-top {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 20px;

      .title {
        font-size: 13px;
        line-height: 22px;
        color: rgba(76, 78, 100, 0.8);
      }

      .swiper-buttons {
        display: flex;
        gap: 10px;

        button {
          display: flex;
          align-items: center;
          justify-content: center;
          text-align: center;
          width: 18px;
          height: 18px;
          border: none;
          box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.1);
          background: $theme-color-2;
          border-radius: 100%;
          cursor: pointer;
        }
        .back {
          .back-arrow {
            rotate: 180deg;
          }
        }
        .next {
          .back-arrow {
            margin-left: 1px;
          }
        }
      }
    }

    .content {
      margin-top: 30px;
      position: relative;
      padding: 20px;
      justify-content: center;
      text-align: center;
      min-height: 100px;
      width: 100%;
      background: #ffffff;
      box-shadow: 0px 0px 6px rgba(221, 221, 221, 0.25);
      border-radius: 15px;

      .profile {
        position: absolute;
        top: -25px;
        left: 50%;
        transform: translateX(-50%);
        width: 60px;
        height: 60px;
        border-radius: 100%;

        img {
          border: 5px solid $theme-color;
          border-radius: 100%;
        }
      }

      .text {
        padding-top: 20px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;
        gap: 5px;

        .name {
          font-size: 13px;
          line-height: 22px;
          color: $theme-color-3;
        }

        .role {
          font-size: 12px;
          line-height: 15px;
          color: rgba(76, 78, 100, 0.5);
        }
      }
    }
  }

  .user-path {
    position: relative;
    display: flex;
    flex-direction: row;
    gap: 10px;
    margin-top: 60px;
    justify-content: end;
    color: #22223b;
    align-items: center;
    margin-bottom: 30px !important;

    .path {
      display: flex;
      gap: 10px;
      position: absolute;
      right: 0px;
      cursor: pointer;
      transition: right 0.3s ease-in-out;

      &:hover {
        position: absolute;
        right: -10px;
        color: $theme-color-2;
      }
    }
  }
}

/* Swiper */
.swiper-button-disabled {
  background: rgba(76, 78, 100, 0.1) !important;
  cursor: not-allowed !important;
}

/*M+Safety Vector Users*/
.user-counter {
  position: absolute;
  z-index: 1;
  user-select: none;
  width: 32.72%;
  height: 28.59%;
  text-align: center;
  padding-top: 10px;

  img {
    width: 21px;
    height: 20px;
    object-fit: cover;
    margin: 5px;
  }
}

.first-user-counter {
  top: 74.5%;
  left: 0.2%;
}

.second-user-counter {
  top: 45.5%;
  left: 33.7%;
}

.third-user-counter {
  top: 18%;
  left: 68%;
}

/* Footer */
.footer {
  height: 65px;
  padding: 0 30px 0 160px;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: space-between;

  .copyright {
    font-weight: 400;
    font-size: 12px;
    color: #bdbdbd;
  }

  .trademark {
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    gap: 10px;
    font-weight: 400;
    font-size: 12px;
    color: #bdbdbd;

    .msafety {
      display: flex;
      gap: 10px;

      hr {
        width: 1px;
        height: 14px;
        background: #bdbdbd;
        margin: 0;
        border-radius: 2px;
      }
    }
    .mekap {
      display: flex;
      gap: 5px;
    }
  }
}

// Notification Popup
.notification-container {
  font-size: 14px;
  box-sizing: border-box;
  position: absolute;
  z-index: 10;

  .notification {
    max-width: 320px;
    height: 70px;
    display: flex;
    align-items: center;
    gap: 5px;
    border: 2px solid gray;
    border-radius: 30px;
    background-color: var(--theme-color-1);

    margin-bottom: 15px;
    position: relative;

    padding: 20px;
    .notification-content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: 10px;

      .notification-title {
        font-size: 15px;
        line-height: 18px;
        color: gray;
      }

      .notification-message {
        font-size: 13px;
        line-height: 16px;
        color: var(--theme-color-2);
      }
    }
  }

  .success-toast {
    border: 2px solid #7ae647;
    .notification-title {
      color: #7ae647 !important;
    }
  }

  .error-toast {
    border: 2px solid #e8140c;
    .notification-title {
      color: #e8140c !important;
    }
  }
}

.top-right {
  top: 10px;
  right: 10px;
}

.top-left {
  top: 10px;
  left: 10px;
}

@import "./media-queries.scss"; // Media Queries
