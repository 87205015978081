@media screen and (min-width: 961px) and (max-width: 1199px) {
  #main-card {
    min-height: 0 !important;
  }

  .top-info {
    display: flex !important;
    flex-wrap: wrap;

    #top-info-card {
      width: calc(50% - 20px);
      box-sizing: border-box;
    }
  }
}

@media screen and (min-width: 320px) and (max-width: 960px) {
  .mobile-header {
    align-items: center;
    z-index: 9999 !important;
    position: absolute !important;
    padding: 0 30px !important;
    top: 25px !important;
    width: 100% !important;
    display: flex !important;
    flex-direction: row !important;
    justify-content: space-between !important;

    .user-info {
      display: flex;
      align-items: center;
      gap: 20px;

      .user-image {
        padding-left: 10px;

        img {
          border-radius: 100% !important;
          border: 5px solid $theme-color-2 !important;
        }
      }
    }

    .icon-hover {
      display: flex !important;
      cursor: pointer !important;
      justify-content: center !important;
      align-items: center !important;
      text-align: center !important;
      width: 35px !important;
      height: 35px !important;
      border-radius: $border-radius !important;
      &:hover {
        background-color: $theme-color-1 !important;
      }
    }
  }

  #main-card {
    padding: 30px !important;

    .m-plus-safety-image {
      padding-top: 0 !important;
      width: 100%;
      max-width: 400px;
      aspect-ratio: 100/299.54;

      & > img {
        position: relative !important;
        width: 100% !important;
        height: 100% !important;
        object-fit: cover;
      }
    }
  }

  .hide-m-plus-safety {
    display: none !important;
  }

  .hide-m-plus-safety-mobile {
    display: initial !important;
  }

  .door {
    top: initial;
    bottom: 4%;
    left: 4% !important;
    width: 100%;

    img {
      width: 50% !important;
    }
  }

  .user-counter {
    height: 15%;
  }

  .third-user-counter {
    top: initial;
    bottom: 5%;
    left: 0;
    width: 100%;
  }

  .second-user-counter {
    top: 40%;
    left: 0;
    width: 100%;
  }

  .first-user-counter {
    top: 5%;
    left: 0;
    width: 100%;
  }

  .auth-control {
    width: 35%;
    right: 0px;
    top: initial;
    bottom: 30%;
  }

  #dashboard {
    padding: 100px 30px 0 0 !important;
    transition: padding 0.4s ease-in-out;
  }

  #navbar {
    display: none !important;
    left: -150px;
    transition: display 0.4s ease-in-out;
  }

  .top-info {
    display: flex !important;
    flex-wrap: wrap;

    #top-info-card {
      min-width: 100% !important;
    }
  }

  .footer {
    padding: 0 30px !important;
    height: 120px !important;
    flex-direction: column;
    gap: 5px;
    justify-content: center !important;
    text-align: center !important;
    align-items: center !important;
  }
}
